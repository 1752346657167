/*
 Body Content Component
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";

import './BodyContent.scss';

//plugins
import lax from 'lax.js';
import NumberItem from '../NumberItem';
import MinySubHeading from '../MinySubHeading';

// style variables
import variables from '../../config_var/config_var.js';
import placeholderHeadshot from '../../media/images/placeholder-headshot.png';

// team
const team = [
  {
    headshot: 'https://digital-assets-web.s3.amazonaws.com/profile-pic.png',
    name: 'Mathew Bowyer',
    jobTitle: 'CTO / Founder'
  },
  {
    headshot: 'https://digital-assets-web.s3.amazonaws.com/profile-pic+(4).png',
    name: 'Angelina Cheung',
    jobTitle: 'CMO / Data analyst'
  },
  {
    headshot: 'https://digital-assets-web.s3.amazonaws.com/profile-pic+(5).png',
    name: 'Luke Davies',
    jobTitle: 'COO / Technical Lead'
  },

  {
    headshot: 'https://digital-assets-web.s3.us-east-1.amazonaws.com/riya-headshot-2.png',
    name: 'Riya Aris',
    jobTitle: 'CPO / Account Director'
  },
]

export default class BodyContent extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  scrollMoreDown = () => {

    let windowHeight = window.innerHeight;
    windowHeight = windowHeight - 100;
    window.scrollTo({
        top: windowHeight,
        left: 100,
        behavior: 'smooth'
      });

    };


  render(){

    const {

      subinfo
      
    } = this.props;

    const secondary_color = variables.secondary_base_color;



   return (


      <div className="body-content-wrapper animate-in" id="wrapper">
        <div className="animate-in col-50">
        <NumberItem 
        number="02"
        position="absolute"
        left="-75px"
        color={variables.primary_font_color}
        top="133px"
        />
          <h6
            className="home-sub-row-heading"
            >
            Your web presence is your digital image/footprint online. First impressions do matter and presenting yourself, your products and services to your target market is key. 

        </h6>
        <MinySubHeading
        heading="what we do"
        width="144px"
        left="-66px"
        />
         <p
           style={{width:'90%',marginRight:'0',paddingRight:'0',textAlign:'left'}}
            >

           Layercayk specialises in crafting <strong>high-end bespoke websites</strong> and <strong>digital products</strong>.<br/><br/> By operating without a traditional office, keeping overheads minimal and offering flexible work arrangements, we attract local and global top tier talent to deliver high-quality digital solutions that <strong>rival agency standards, all at cost-effective freelance pricing</strong>.<br/><br/><Link to="/services" title="Our services" className="inline-link">View our services</Link>
        </p>

        </div>
        <div className="team-section col-50">
      
          <div className="team-wrapper-section flex flex-wrap">
            {
              team.map(member =>

                <article className="member">
                  <span className="headshot-wrapper">
                   <img src={member.headshot} alt="headshot"/>
                  </span>
                  <h5>{member.name}</h5>
                  <p><span className="highlight">{member.jobTitle}</span></p>
                </article>

              )
            }
            <button className="member-count">+20 TEAM MEMBERS</button>
          </div>
        </div>
      </div>
        
    );
  }
}


